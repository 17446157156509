import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { InvoiceService } from './invoice.service';

@Injectable()
export class InvoiceGuard implements CanActivate {

    constructor(
        private invoiceService: InvoiceService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.invoiceService.getProducts().pipe(map(() => true));
    }
}
