import { Injectable, Injector } from '@angular/core';
import { SapInvoiceDto, SapInvoiceServiceProxy, SearchInvoicesInput, SearchInvoicesOutput, GetEmploymentDto, SapCancellationReasonDto, SapProductDto, SearchProductsInput, SearchProductsOutput, SearchCancellationReasonsOutput, SearchCancellationReasonsInput, SapBusinessPartnerSearchQueryDto, SapBusinessPartnerDto, SapPersonDto, InvoiceAddressDto, ContactDto, SapSponsorshipDto, SapBusinessDto, PersonLicenceType, CreateOrUpdateSapInvoiceDto, SponsorshipInvoiceAddressDto } from '@shared/service-proxies/service-proxies';
import { PersonDossierService } from './person-dossier.service';
import { tap, Observable } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { AppComponentBase } from '../../../shared/common/app-component-base';
import { AppConsts } from '../../../shared/AppConsts';
import { SponsorshipDossierService } from './sponsorship-dossier.service';

@Injectable({
    providedIn: 'root',
})
export class InvoiceService extends AppComponentBase {

    public allProducts: SapProductDto[] = [];

    constructor(
        injector: Injector,
        private invoiceService: SapInvoiceServiceProxy,
        private personDossierService: PersonDossierService,
        private sponsorshipDossierService: SponsorshipDossierService
    ) {
        super(injector);
    }

    public GetInvoices(sorting: string, filter: string, skipCount: number, maxCount: number): Observable<SearchInvoicesOutput> {
        const input = new SearchInvoicesInput();
        input.filter = filter;
        input.sorting = sorting;
        input.maxResultCount = maxCount;
        input.skipCount = skipCount;
        return this.invoiceService.search(input);
    }

    public GetInvoicesForPersonOrSponsorship(sorting: string, filter: string, skipCount: number, maxCount: number): Observable<SearchInvoicesOutput> {
        const input = new SearchInvoicesInput();
        input.personId = this.personDossierService.GetId();
        input.sponsorshipId = this.sponsorshipDossierService.GetId();
        input.filter = filter;
        input.sorting = sorting;
        input.maxResultCount = maxCount;
        input.skipCount = skipCount;
        return this.invoiceService.search(input);
    }

    public getInvoice(id: number): Observable<SapInvoiceDto> {
        return this.invoiceService.get(id);
    }

    public getEmptyInvoice(personId: number, sponsorshipId: number): Observable<SapInvoiceDto> {
        return this.invoiceService.getEmpty(personId, sponsorshipId);
    }

    public createInvoice(dto: CreateOrUpdateSapInvoiceDto): Observable<SapInvoiceDto> {
        return this.invoiceService.create(dto);
    }

    public updateInvoice(invoiceId: number, dto: CreateOrUpdateSapInvoiceDto): Observable<SapInvoiceDto> {
        return this.invoiceService.update(invoiceId, dto);
    }

    public releaseInvoice(invoiceId: number): Observable<SapInvoiceDto> {
        return this.invoiceService.releaseInvoice(invoiceId);
    }

    public cancelInvoice(invoiceId: number, cancellationReasonId: number): Observable<void> {
        return this.invoiceService.cancelInvoice(invoiceId, cancellationReasonId);
    }

    public deleteInvoice(id: number): Observable<void> {
        return this.invoiceService.delete(id);
    }

    public createInvoiceForPersonDisciplinaryMeasures(id: number): Observable<number> {
        return this.invoiceService.createInvoiceForPersonDisciplinaryMeasures(id);
    }

    public createInvoiceForPersonLicence(id: number): Observable<number> {
        return this.invoiceService.createInvoiceForPersonLicence(id);
    }

    public createInvoiceForPersonLicenceRenewal(id: number): Observable<number> {
        return this.invoiceService.createInvoiceForPersonLicenceRenewal(id);
    }

    public createInvoiceForCorporateLicence(id: number): Observable<number> {
        return this.invoiceService.createInvoiceForCorporateLicence(id);
    }

    public createInvoiceForCorporateLicenceRenewal(id: number): Observable<number> {
        return this.invoiceService.createInvoiceForCorporateLicenceRenewal(id);
    }

    public getSapQueryForPerson(personId: number): Observable<SapBusinessPartnerSearchQueryDto> {
        return this.invoiceService.getSapQueryForPerson(personId);
    }

    public getSapQueryForBusiness(businessId: number): Observable<SapBusinessPartnerSearchQueryDto> {
        return this.invoiceService.getSapQueryForBusiness(businessId);
    }

    public getSapQueryForSponsorship(sponsorshipId: number): Observable<SapBusinessPartnerSearchQueryDto> {
        return this.invoiceService.getSapQueryForSponsorship(sponsorshipId);
    }

    public searchBusinessPartner(query: SapBusinessPartnerSearchQueryDto, cantonalDepartmentId: string): Observable<SapBusinessPartnerDto[]> {
        return this.invoiceService.searchBusinessPartner(cantonalDepartmentId, query);
    }

    public assignBusinessPartner(sapId: string, personId: number, businessId: number, sponsorshipId: number): Observable<void> {
        personId = personId ?? undefined;
        businessId = businessId ?? undefined;
        sponsorshipId = sponsorshipId ?? undefined;
        return this.invoiceService.assignBusinessPartner(sapId, personId, businessId, sponsorshipId);
    }

    public createAndAssignBusinessPartner(invoiceId: number, addressTypeId: string, personId: number, businessId: number, sponsorshipId: number, cantonalDepartmentId: string): Observable<string> {
        personId = personId ?? undefined;
        businessId = businessId ?? undefined;
        sponsorshipId = sponsorshipId ?? undefined;
        addressTypeId = addressTypeId ?? undefined;
        return this.invoiceService.createAndAssignBusinessPartner(invoiceId, addressTypeId, personId, businessId, sponsorshipId, cantonalDepartmentId);
    }

    // These offset are used to avoid id conflicts where persons, businesses and sponsorships are included in the same dropdown. Businesses have no offset.
    public PersonIdOffset = 10000000;
    public SponsorshipIdOffset = 20000000;

    createPersonBillingAddressDropdownList(person: SapPersonDto, employments: GetEmploymentDto[]): SelectItem[] {
        let dropdownList: SelectItem[] = [];

        if (person?.invoiceAddress) {
            dropdownList.push({ value: AppConsts.codes.invoiceAddressType.invoice, label: this.l('InvoiceAddressType-Invoice') + ': ' + this.getPersonInvoiceAddressDescription(person.invoiceAddress) });
        }

        if (person?.contact) {
            dropdownList.push({ value: AppConsts.codes.invoiceAddressType.private, label: this.l('InvoiceAddressType-Private') + ': ' + this.getPrivateAddressDescription(person.contact) });
        }

        employments.forEach(employment => {
            if ((employment.personLicence?.discriminator === PersonLicenceType.ProfessionalLicence || employment.personLicence?.discriminator === PersonLicenceType.ProfessionalNinetyDays) && !!employment.businessId) {
                dropdownList.push({ value: employment.businessId, label: this.getEmploymentAddressBusinessDescription(employment) });
            }

            if ((employment.personLicence?.discriminator === PersonLicenceType.ProfessionalLicence || employment.personLicence?.discriminator === PersonLicenceType.ProfessionalNinetyDays) && !!employment.sponsorshipId) {
                dropdownList.push({ value: employment.sponsorshipId + this.SponsorshipIdOffset, label: this.getEmploymentAddressSponsorshipDescription(employment) });
            }
        });

        return dropdownList;
    }

    createSponsorshipBillingAddressDropdownList(sponsorship: SapSponsorshipDto, businesses: SapBusinessDto[]): SelectItem[] {
        let dropdownList: SelectItem[] = [];

        if (sponsorship?.invoiceAddress) {
            dropdownList.push({ value: AppConsts.codes.invoiceAddressType.invoice, label: this.l('InvoiceAddressType-Invoice') + ': ' + this.getSponsorshipInvoiceAddressDescription(sponsorship.invoiceAddress) });
        }

        if (sponsorship?.street) {
            dropdownList.push({ value: AppConsts.codes.invoiceAddressType.private, label: this.l('SponsorshipAddress') + ': ' + this.getSponsorshipAddressDescription(sponsorship) });
        }

        businesses.forEach(business => {
            dropdownList.push({ value: business.id, label: this.getBusinessAddressDescription(business) });
        });

        return dropdownList;
    }

    createAlternateBillingAddressDropdownList(employments: GetEmploymentDto[]): SelectItem[] {
        let dropdownList: SelectItem[] = [];

        employments.forEach((employment: GetEmploymentDto) => {
            if (!(employment.personLicence?.discriminator === PersonLicenceType.ProfessionalLicence || employment.personLicence?.discriminator === PersonLicenceType.ProfessionalNinetyDays) && !!employment.businessId) {
                dropdownList.push({ value: employment.businessId, label: this.getEmploymentAddressBusinessDescription(employment) });
            }

            if (!(employment.personLicence?.discriminator === PersonLicenceType.ProfessionalLicence || employment.personLicence?.discriminator === PersonLicenceType.ProfessionalNinetyDays) && !!employment.sponsorshipId) {
                dropdownList.push({ value: employment.sponsorshipId + this.SponsorshipIdOffset, label: this.getEmploymentAddressSponsorshipDescription(employment) });
            }
        });

        return dropdownList;
    }

    createProductsDropdownList(products: SapProductDto[], cantonalDepartmentId: string): SelectItem[] {
        let dropdownList: SelectItem[] = [];

        products.filter(x => x.cantonalDepartmentId === cantonalDepartmentId).forEach(product => {
            dropdownList.push({ value: product.id, label: this.createProductLabel(product) });
        });

        return dropdownList;
    }

    createProductLabel(product: SapProductDto): string {
        return `${product.sapId}: ${product.sapDescription}${product.detailedDescription ? ' ' + product.detailedDescription : ''}, CHF:${product.pricePerUnit?.toFixed(2)}`;
    }

    createCancellationReasonsDropdownList(reasons: SapCancellationReasonDto[]): SelectItem[] {
        let dropdownList: SelectItem[] = [];

        reasons.forEach(reason => {
            dropdownList.push({ value: reason.id, label: reason.sapDescription });
        });

        return dropdownList;
    }

    private getPrivateAddressDescription(contact: ContactDto) {
        let str = contact.street ?? '';

        if (contact.streetNr && contact.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(contact.streetNr);
        }

        if (!!contact.postCode && contact.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(contact.postCode);
        }

        str = str?.concat(' ');
        str = str?.concat(contact.city);

        return str;
    }

    private getSponsorshipAddressDescription(sponsorship: SapSponsorshipDto) {
        let str = sponsorship.street ?? '';

        if (sponsorship.streetNr && sponsorship.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(sponsorship.streetNr);
        }

        if (!!sponsorship.postCode && sponsorship.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(sponsorship.postCode);
        }

        str = str?.concat(' ');
        str = str?.concat(sponsorship.city);

        return str;
    }

    private getPersonInvoiceAddressDescription(invoiceAddress: InvoiceAddressDto) {
        let str = invoiceAddress.street ?? '';

        if (!!invoiceAddress.streetNr && invoiceAddress.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(invoiceAddress.streetNr);
        }

        if (!!invoiceAddress.postCode && invoiceAddress.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(invoiceAddress.postCode);
        }

        str = str?.concat(' ');
        str = str?.concat(invoiceAddress.city);

        return str;
    }

    private getSponsorshipInvoiceAddressDescription(invoiceAddress: SponsorshipInvoiceAddressDto) {
        let str = invoiceAddress.street ?? '';

        if (!!invoiceAddress.streetNr && invoiceAddress.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(invoiceAddress.streetNr);
        }

        if (!!invoiceAddress.postCode && invoiceAddress.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(invoiceAddress.postCode);
        }

        str = str?.concat(' ');
        str = str?.concat(invoiceAddress.city);

        return str;
    }

    private getEmploymentAddressBusinessDescription(employment: GetEmploymentDto): string {

        let str = employment.business.name ?? '';

        if (employment.business.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.business.street);

            if (!!employment.business.streetNr && employment.business.streetNr.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.business.streetNr);
            }
        }

        if (!!employment.business.postCode && employment.business.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.business.postCode);
        }

        if (!!employment.business.city && employment.business.city.length > 0) {
            if (str.length > 0) {
                str = str.concat(' ');
            }

            str = str.concat(employment.business.city);
        }

        if (employment.personLicence) {
            return str + ` (Lizenz: ${this.l('PersonLicenceType_' + PersonLicenceType[employment.personLicence.discriminator] + '-Short')}, SAP-ID: ${employment.business.sapBusinessPartner?.sapId ?? '-'}, intercompany: ${(employment.business.sapBusinessPartner?.isInterCompany === true ? 'Ja' : employment.business.sapBusinessPartner?.isInterCompany === false ? 'Nein' : '-')})`;
        } else {
            return str + ` (SAP-ID: ${employment.business.sapBusinessPartner?.sapId ?? '-'}, intercompany: ${(employment.business.sapBusinessPartner?.isInterCompany === true ? 'Ja' : employment.business.sapBusinessPartner?.isInterCompany === false ? 'Nein' : '-')})`;
        }
    }

    private getEmploymentAddressSponsorshipDescription(employment: GetEmploymentDto): string {

        let str = employment.sponsorship.name ?? '';

        if (employment.sponsorship.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.sponsorship.street);

            if (!!employment.sponsorship.streetNr && employment.sponsorship.streetNr.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.sponsorship.streetNr);
            }
        }

        if (!!employment.sponsorship.postCode && employment.sponsorship.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.sponsorship.postCode);
        }

        if (!!employment.sponsorship.city && employment.sponsorship.city.length > 0) {
            if (str.length > 0) {
                str = str.concat(' ');
            }

            str = str.concat(employment.sponsorship.city);
        }

        if (employment.personLicence) {
            return str + ` (Lizenz: ${this.l('PersonLicenceType_' + PersonLicenceType[employment.personLicence.discriminator] + '-Short')}, SAP-ID: ${employment.sponsorship.sapBusinessPartner?.sapId ?? '-'}, intercompany: ${(employment.sponsorship.sapBusinessPartner?.isInterCompany === true ? 'Ja' : employment.sponsorship.sapBusinessPartner?.isInterCompany === false ? 'Nein' : '-')})`;
        } else {
            return str + ` (SAP-ID: ${employment.sponsorship.sapBusinessPartner?.sapId ?? '-'}, intercompany: ${(employment.sponsorship.sapBusinessPartner?.isInterCompany === true ? 'Ja' : employment.sponsorship.sapBusinessPartner?.isInterCompany === false ? 'Nein' : '-')})`;
        }
    }

    private getBusinessAddressDescription(business: SapBusinessDto): string {

        let str = business.name ?? '';

        if (business.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(business.street);

            if (!!business.streetNr && business.streetNr.length > 0) {
                str = str.concat(' ');
                str = str.concat(business.streetNr);
            }
        }

        if (!!business.postCode && business.postCode.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(business.postCode);
        }

        if (!!business.city && business.city.length > 0) {
            if (str.length > 0) {
                str = str.concat(' ');
            }

            str = str.concat(business.city);
        }

        return str + ` (SAP-ID: ${business.sapId})`;
    }

    public searchProducts(sorting: string, filter: string, skipCount: number, maxCount: number): Observable<SearchProductsOutput> {
        const input = new SearchProductsInput();
        input.filter = filter;
        input.sorting = sorting;
        input.maxResultCount = maxCount;
        input.skipCount = skipCount;
        return this.invoiceService.searchProducts(input);
    }

    public getProducts(): Observable<SapProductDto[]> {
        return this.invoiceService.getProducts(true).pipe(tap((products: SapProductDto[]) => {
            this.allProducts = products;
        }));
    }

    public getProduct(id: number): Observable<SapProductDto> {
        return this.invoiceService.getProduct(id);
    }

    public createOrUpdateProduct(dto: SapProductDto): Observable<SapProductDto> {
        return this.invoiceService.createOrUpdateProduct(dto);
    }

    public deleteProduct(id: number): Observable<void> {
        return this.invoiceService.deleteProduct(id);
    }

    public searchCancellationReasons(sorting: string, filter: string, skipCount: number, maxCount: number): Observable<SearchCancellationReasonsOutput> {
        const input = new SearchCancellationReasonsInput();
        input.filter = filter;
        input.sorting = sorting;
        input.maxResultCount = maxCount;
        input.skipCount = skipCount;
        return this.invoiceService.searchCancellationReasons(input);
    }

    public getCancellationReasons(): Observable<SapCancellationReasonDto[]> {
        return this.invoiceService.getCancellationReasons();
    }

    public getCancellationReason(id: number): Observable<SapCancellationReasonDto> {
        return this.invoiceService.getCancellationReason(id);
    }

    public createOrUpdateCancellationReason(dto: SapCancellationReasonDto): Observable<SapCancellationReasonDto> {
        return this.invoiceService.createOrUpdateCancellationReason(dto);
    }

    public deleteCancellationReason(id: number): Observable<void> {
        return this.invoiceService.deleteCancellationReason(id);
    }
}
